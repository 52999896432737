.logoImg {
    animation: logo-spin infinite 25s linear;
    pointer-events: none;
}

@keyframes logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}